/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Log } from '@brandedholdings/react-components/build/utils';

export class Interlincx extends Component {
    constructor(props) {
        super();
        this.primaryAdsRef = React.createRef();
    }

    async componentDidMount() {
        const { config: { zoneMappings: { primary }, params } } = this.props;
        const { onInterlincxClick } = this.props;
        Object.keys(params).forEach(key => params[key] === null && delete params[key]);
        console.log('InterlincxParameters', params);
        window.adverse(this.primaryAdsRef.current, {
            zoneId: primary,
            displayLocation: 'fm-offerwall',
            ...params,
        });
        // TODO; Handle promise rejection (e.g. if there is a problem loading the zones)
        const ctas = await this.watchForInterlincxLoad();

        for (let i = 0; i < ctas.length; i++) {
            const cta = ctas.item(i);
            const a = cta.getElementsByTagName('a').item(0);
            a.onclick = onInterlincxClick;
        }
    }

    watchForInterlincxLoad = () => {
        return new Promise((resolve, reject) => {
            let counter = 0;
            const poller = setInterval(() => {
                const ctas = document.getElementsByClassName('offer__cta');
                if (ctas.length) {
                    clearInterval(poller);
                    resolve(ctas);
                } else if (++counter > 50) {
                    reject();
                }
            }, 100);
        });
    }

    render() {
        return (
            <div ref={this.primaryAdsRef} />
        );
    }
}

Interlincx.propTypes = {
    config: PropTypes.shape({
        primary: PropTypes.shape({
            zoneId: PropTypes.string.isRequired,
        }),
        params: PropTypes.shape({}),
    }).isRequired,
    onInterlincxClick: PropTypes.func.isRequired,
};

export default Interlincx;
