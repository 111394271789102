import React from 'react';
import { connect } from 'react-redux';
import {
    Form,
    PegasusPageView,
    TrustIcons,
    utils,
} from '@brandedholdings/react-components';
import DefaultPageWrapper from '../DefaultPageWrapper';
import PrepopulatedFields from '../../models/PrepopulatedFields';
import Field from '../FormField';
import { siteMeta } from '../../data/site_meta.yml';

class CreditReportStep extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneMask: undefined, // utils.FieldFormats.formats.tel
        };
    }

    componentDidMount() {
        const { unwrap, siteMeta } = this.props;
        if (!unwrap) { new PegasusPageView(siteMeta).postPageView(); }
    }

    formRefCallback = (ref) => {
        if (!ref) return;
        const { fields } = this.props;
        const { phoneMask } = this.state;

        ref.hydrate({
            ...fields,
            ...PrepopulatedFields.mapFields(),
        });

        if (!(this.state && phoneMask)) {
            this.setState({
                phoneMask: utils.FieldFormats.formats.tel,
            });
        }
    }

    render() {
        const {
            handleStepSubmit,
            initialValues,
            unwrap,
            onCloseModal,
        } = this.props;


        return (
            <Form
                className="form form--credit-report text-align--center"
                onSubmit={handleStepSubmit}
                method="post"
                name="credit-report-step"
                initialValues={initialValues}
                ref={this.formRefCallback}
                unwrap={unwrap}
            >
                <br />
                <h4>Sign up for more great offers from Finance Matrix</h4>
                <br />
                <br />
                <div className="field">
                    <label className="matrix--label">First Name</label>
                    <div className="field--container">
                        <Field
                            type="text"
                            name="nameFirst"
                            id="nameFirst"
                            autoComplete="given-name"
                        />
                        { Field.renderFieldError('nameFirst') }
                    </div>
                </div>
                <div className="field">
                    <label className="matrix--label">Last Name</label>
                    <div className="field--container">
                        <Field
                            type="text"
                            name="nameLast"
                            id="nameLast"
                            autoComplete="family-name"
                        />
                        { Field.renderFieldError('nameLast') }
                    </div>
                </div>
                <div className="field">
                    <label className="matrix--label">Email</label>
                    <div className="field--container">
                        <Field
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                        />
                        { Field.renderFieldError('email') }
                    </div>
                </div>
                <div className="field">
                    <label className="matrix--label">Phone Number</label>
                    <div className="field--container">
                        <Field
                            type="tel"
                            id="primaryPhone"
                            name="primaryPhone"
                            autoComplete="tel-national"
                            mask="000-000-0000"
                            placeholder="   -   -    "
                        />
                        { Field.renderFieldError('primaryPhone') }
                    </div>
                </div>
                <Field
                    type="checkbox"
                    id="smsOptIn_id"
                    name="smsOptIn.id"
                    value="157"
                    label={(
                        <span>
                            <strong>
                                Yes, I’d like to receive financial product and service offers via text message
                            </strong>
                            <br />
                            {' '}
                            By checking this box, I verify that this is my mobile phone number and that I would like to receive recurring financial product or service offers via text messages, including for marketing purposes (including but not limited to those using an automatic telephone dialing system or prerecorded messages), at the number I have provided from Finance-Matrix.com even if this number is listed on any federal or state do not call registry. I understand that I am not required to provide my consent as a condition of purchasing any products or services. Text STOP to stop and HELP for help. Message and data rates may apply.
                        </span>
                    )}
                />
                { Field.renderFieldError('smsOptIn.id') }
                <br />
                <button
                    type="submit"
                    className="button button--success button--big"
                >
                    Sign Up & Continue
                </button>
                <br />
                <br />
                <TrustIcons wrapper={({ children }) => <div>{children}</div>} icons={['secured', 'mcafee-secure']} cssModifiers={['alt']} siteMeta={siteMeta} height={48} />
                <div className="text-align--center">
                    <button
                        type="button"
                        className="form--credit-report__cancel button-text-link"
                        onClick={onCloseModal}
                    >
                        No Thanks, Continue
                    </button>
                </div>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {
        fields: state.applicantFields,
        fieldErrors: state.fieldErrors,
    };
}

export default connect(mapStateToProps, null)(CreditReportStep);
