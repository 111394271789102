import {
    Hero,
    SEO,
    Waistband,
    Modal,
    Log,
    PegasusClient,
} from '@brandedholdings/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import PagePost from '../../models/PagePost';
import { mediaNet as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import { InterlincxContainer } from '../../components/Interlincx/InterlincxContainer';
import ResultsForm from '../../components/ResultsForm';
import PrepopulatedFields from '../../models/PrepopulatedFields';

class InterlincxResults extends React.Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            modalIsOpen: false,
            clickedElement: null,
            modalEvaluated: false,
        };
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    openModal = (e, offer) => {
        e.preventDefault();
        if ('history' in window) window.history.pushState({}, '', `${document.location.search}#credit-report-step`);
        Log.toAnalyticsEvent('Offer', 'Select', `${offer.body.offer_name} - ${offer.banner_id}`, true);
        this.modalRef.current.openModal();
    }

    onInterlincxClick = (e) => {
        const { clickedElement } = this.state;
        if (clickedElement == null) {
            e.preventDefault();
            console.log('e', e, this.modalRef);
            if (PrepopulatedFields.isPrepopulated(['primaryPhone'])) {
                const fields = PrepopulatedFields.mapFields();
                const { primaryPhone } = fields;
                new PegasusClient(siteMeta).getIsSubscribed(primaryPhone, siteMeta.optInEntityId).then(
                    (res) => {
                        if (res.data.is_subscribed === true) {
                            this.setState({ clickedElement: e.target });
                            this.onModalFormSubmit();
                        } else {
                            this.setState({ clickedElement: e.target, modalIsOpen: true });
                        }
                    }, () => {
                        this.setState({ clickedElement: e.target, modalIsOpen: true });
                    }
                );
            }
            this.setState({ clickedElement: e.target, modalIsOpen: true });
        }
    }

    onModalFormSubmit = () => {
        const { clickedElement } = this.state;
        console.log('clickedElement', clickedElement);
        clickedElement.click();
        this.setState({ modalEvaluated: true, modalIsOpen: false });
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const { template } = pageMeta;
        const { modalIsOpen } = this.state;

        return (
            <PageWrapper location={this.props.location} templateType={template} adserve={true}>
                <SEO post={post} />
                <Hero
                    heading="Great News!"
                    subheading="Personal loan options are available!"
                    actionText={null}
                    actionLink={"#"}
                    heroModifiers="hero--arrow"
                    actionModifiers={null}
                />
                <Waistband color="gray">
                    <div className="layout">
                        <div id="results-container">
                            <InterlincxContainer onInterlincxClick={this.onInterlincxClick} />
                        </div>
                    </div>
                </Waistband>
                <Modal
                    isModalOpen={modalIsOpen}
                    heading={false}
                    triggerOnClick
                    className="form-modal"
                    onClose={this.onModalFormSubmit}
                    ref={this.modalRef}
                >
                    <ResultsForm
                        onSubmit={this.onModalFormSubmit}
                        onCloseModal={this.onModalFormSubmit}
                    />
                </Modal>
            </PageWrapper>
        )
    }
}

InterlincxResults.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default InterlincxResults;
