import React from 'react';
import {
    Flow,
    PersonalLoanForm,
    Step,
    utils,
} from '@brandedholdings/react-components';
import DataMap from '@brandedholdings/react-components/build/PegasusClient/DataMap';
import CreditReportStep from './CreditReportStep';
import ResultsTransferStep from './ResultsTransferStep';


const { Log } = utils;

export default class ResultsForm extends React.Component {
    constructor(props) {
        super(props);

        this.flow = new Flow();
        this.flow.addStep(new Step({
            name: 'credit-report-step', type: CreditReportStep, nextStep: 'results-transfer', afterNextStep: this.onModalFormSubmit,
        }));
        this.flow.addStep(new Step({ name: 'results-transfer', type: ResultsTransferStep }));
    }

    onModalFormSubmit = (response) => {
        Log.toDataLayer({
            event: 'form.modal_submit',
        });

        if (response.success === true) {
            console.warn('Applicant result: success');
            const { onSubmit } = this.props;

            if (onSubmit instanceof Function) {
                onSubmit();
            }
        } else {
            console.warn('Applicant result: fail');
        }
    };

    validate(values) {
        const errors = {};

        // required field stuff
        values = new DataMap(values).toPegasus();

        function requireField(name, errorMessage, type = 'string') {
            if (type === 'string' && !values[name]) {
                errors[name] = errorMessage;
            }

            if (type === 'boolean' && (typeof values[name] === 'string' && values[name] === '')) {
                errors[name] = errorMessage;
            }
        }

        requireField('nameFirst', 'First name is required.');
        requireField('nameLast', 'Last name is required.');
        requireField('email', 'Email address is required.');
        requireField('primaryPhone', 'Phone number is required.');

        if ('creditReportOptIn.id' in values && !values['creditReportOptIn.id'] && 'smsOptIn.id' in values && !values['smsOptIn.id']) {
            errors['smsOptIn.id'] = 'Select at least one option to continue.';
        }

        return errors;
    }

    render() {
        return (
            <PersonalLoanForm
                {...this.props}
                id="results-form"
                className=""
                flow={this.flow}
                validate={this.validate}
                minLoanValue={0}
                maxLoanValue={1}
                modalDisclaimerText="FinanceMatrix.com is not a lender."
                smsDisclaimerText="I understand that I am not required to provide my consent as a condition of purchasing any products or services. You can contact customer care at (855) 846-9666. To opt out at any time reply STOP; HELP for info. Text STOP to stop and HELP for help. No more than five (5) messages will be sent per month. Carriers are not liable for delayed or undelivered messages. No sale or dissemination to third parties."
                enableGoogleStreet
            />
        );
    }
}
