/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import { PegasusClient } from '@brandedholdings/react-components';
import { Log } from '@brandedholdings/react-components/build/utils';
import { siteMeta } from '../../data/site_meta.yml';
import { Interlincx } from './Interlincx';

export class InterlincxContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scriptLoaded: false,
            configLoaded: false,
            interlincxConfig: {},
        };
    }

    componentDidMount() {
        const client = new PegasusClient(siteMeta);
        client.getInterlincxConfig()
            .then((res) => {
                this.setState({ interlincxConfig: res.data, configLoaded: true });
            })
            .catch(error => Log.debug(error));
    }

    render() {
        const {
            scriptLoaded,
            configLoaded,
            interlincxConfig,
        } = this.state;
        const { onInterlincxClick } = this.props;

        return (
            <div>
                <Script
                    onError={() => { console.log('error'); }}
                    onLoad={() => this.setState({ scriptLoaded: true })}
                    url="https://adverse.lincx.la/ad"
                    attributes={{ 'data-manual-render': true }}
                />
                { scriptLoaded && configLoaded && (
                    <Interlincx
                        config={interlincxConfig}
                        onInterlincxClick={onInterlincxClick}
                    />
                )}
            </div>
        );
    }
}

InterlincxContainer.propTypes = {
    onInterlincxClick: PropTypes.func.isRequired,
};

export default InterlincxContainer;
