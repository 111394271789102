import React from 'react';
import {
    Form,
    Loader,
} from '@brandedholdings/react-components';
import { Log } from '../../../node_modules/@brandedholdings/react-components/build/utils';

export default class ResultsTransferStep extends React.Component {
    componentDidMount = () => {
        Log.toAnalyticsEvent('Form', 'Submit', 'Opt-In', true);

        // @todo perform opt-in submit stuff here
    };

    render() {
        return (
            <Form className="form">
                <div className="field">
                    <Loader inline message="Redirecting you to the offer site..." />
                </div>
            </Form>
        );
    }
}
