import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import {
    Flash,
    FormInput,
    FormField,
    rootStore,
} from '@brandedholdings/react-components';
import './default.scss';

export default class MatrixField extends FormField {
    static propTypes = {
        icon: PropTypes.string,
        prefix: PropTypes.string,
    };

    static getFieldError(name) {
        const { fieldErrors } = rootStore.getState();
        return fieldErrors && fieldErrors[name];
    }

    static renderFieldError(name) {
        const error = MatrixField.getFieldError(name);

        return error
            ? <Flash level="error">{ error }</Flash>
            : null;
    }

    render() {
        const {
            children,
            prefix,
            icon,
            ...transferProps
        } = this.props;

        const { type } = this.props;
        const isCheckable = type === 'radio';
        const isCheckbox = type === 'checkbox';

        // autogenerate IDs for inputs that need clickable labels

        if (isCheckable) {
            transferProps.id = transferProps.id || shortid.generate();
        }

        const inputStyle = {};
        const field = (
            <FormInput
                {...transferProps}
                data-field={this.props.name}
                ref={this.refCallback}
            >
                { children }
            </FormInput>
        );

        if (transferProps.type === 'hidden') return field;

        if (icon) {
            inputStyle.backgroundImage = `url(${icon});`;
        }

        if (isCheckable) {
            return (
                <div className={`matrix-field matrix-field--${type}`}>
                    { field }
                    <label htmlFor={transferProps.id} className="matrix-field__input">{ this.props.label }</label>
                </div>
            );
        }

        if (isCheckbox) {
            return (
                <div className={`matrix-field matrix-field--${type}`}>
                    <label htmlFor={transferProps.id} className="control control--checkbox control--large">
                        { field }
                        <span className="control__indicator control__indicator--large" />
                        { this.props.label }
                    </label>
                </div>
            );
        }

        return (
            <div className={`matrix-field matrix-field--${type}`}>
                <label className="matrix-field__input" style={inputStyle}>{ this.props.label }</label>
                { prefix && <div className={`matrix-field__prefix matrix-field__prefix--${prefix}`} /> }
                { field }
            </div>
        );
    }
}
